import React from 'react';
import { Card, Table } from 'reactstrap';

export default class HTable extends React.Component {
  render() {
    const {
      data: {
        name,
        sss_no,
        address,
        email,
        phone,
        local_branch,
        age_group
      },
    } = this.props;
    return (
      <Card
        body
        // className="d-flex justify-content-center align-items-center flex-column"
        style={{ margin: '1%' }}
      >
        <Table responsive>
          <tbody>
            <tr>
              <th scope="row">Name</th>
              <td>{name}</td>
              <th scope="row">Age Group</th>
              <td>{age_group}</td>
            </tr>
            <tr>
              <th scope="row">SSS Number</th>
              <td>{sss_no}</td>
              <th scope="row">Address</th>
              <td>{address}</td>
            </tr>
            <tr>
              <th scope="row">Email</th>
              <td>{email}</td>
              <th scope="row">Mobile Number</th>
              <td>{phone}</td>
            </tr>
            <tr>
              <th scope="row">Local Branch</th>
              <td>{local_branch}</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    );
  }
}
