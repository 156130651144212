import React from 'react';
import componentQueries from 'react-component-queries';
import 'styles/reduction.scss';
import { Col, Spinner } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import HTable from './HTable';
import PayBar from './PayBar';
import axios from 'axios';
import { date } from 'faker/lib/locales/az';

class UserDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      loading: this.props.loading,
      redirect: false,
      paymentData: [],
      totalMoney: 0,
    };
  }

  componentDidMount() {
    axios.get('/api/users/existingUserRegistrationCost').then(res => {
      let payments = [];
      let total = 0;
      const age = this.props.data.age;
      const ageGroup = this.props.data.age_group;

      const age_group_mapper = {
        'Below 30': 'Existing_User_FFC_2025_below_30',
        '30+ to 34': 'Existing_User_FFC_2025_30_34',
        '34+ to 37': 'Existing_User_FFC_2025_34_37',
        '37+ to 40': 'Existing_User_FFC_2025_37_40',
        '40+ to 45': 'Existing_User_FFC_2025_40_45',
        'Above 45': 'Existing_User_FFC_2025_above_45',
        'Above 50': 'Existing_User_FFC_2025_above_50',
        'New-Above 45': 'Existing_User_FFC_2025_above_45_new',
        'New-Above 50': 'Existing_User_FFC_2025_above_50_new',
        'Opted for OLD Scheme': 'Existing_User_FFC_2025_old_scheme'
      };

      total = res.data[age_group_mapper[ageGroup]];
      payments.push({
        type: age_group_mapper[ageGroup]?.replace(/_/g, ' '),
        amount: res.data[age_group_mapper[ageGroup]],
      });

      // if(age <= 30){
      //   let value = res.data['Existing_User_FFC_2024_under_30']
      //   total = value;
      //   payments.push({ type: 'Existing User FFC 2024 under 30', amount: value });
      // }
      // else if(age > 30 && age <= 34){
      //   let value = res.data['Existing_User_FFC_2024_30_34']
      //   total = value;
      //   payments.push({ type: 'Existing User FFC 2024 30-34', amount: value });
      // }
      // else if(age > 34 && age <= 37){
      //   let value = res.data['Existing_User_FFC_2024_34_37']
      //   total = value;
      //   payments.push({ type: 'Existing User FFC 2024 34-37', amount: value });
      // }
      // else if(age > 37 && age <= 40){
      //   let value = res.data['Existing_User_FFC_2024_37_40']
      //   total = value;
      //   payments.push({ type: 'Existing User FFC 2024 37-40', amount: value });
      // }
      // else if(age > 40 && age <= 45){
      //   let value = res.data['Existing_User_FFC_2024_40_45']
      //   total = value;
      //   payments.push({ type: 'Existing User FFC 2024 40-45', amount: value });
      // }
      // else{
      //   let value = res.data['Existing_User_FFC_2024_above_45']
      //   total = value;
      //   payments.push({ type: 'Existing User FFC 2024 above 45', amount: value });
      // }
      let totalPayment = total;
      if (this.props.data.total_unit) {
        totalPayment = total * parseInt(this.props.data.total_unit);
        payments.push({
          type: 'Total Units',
          amount: parseInt(this.props.data.total_unit),
        });
      }

      payments.push({
        type: 'Voluntary Advance Deposit With Us',
        amount: Math.max(parseInt(this.props.data.voluntary_advanced_deposit) - totalPayment, 0),
      });


      this.setState({
        data: {
          ...this.props.data,
          voluntary_advanced_deposit: Math.max(
            parseInt(this.props.data.voluntary_advanced_deposit) - total,
            0,
          ),
        },
      });

      totalPayment -= parseInt(this.props.data.voluntary_advanced_deposit);

      if (this.props.data.dues > 0) {
        payments.push({
          type: 'Dues',
          amount: this.props.data.dues,
        });
        totalPayment = totalPayment + parseInt(this.props.data.dues);
      }

      totalPayment = Math.max(totalPayment, 0);

      if (this.props.data.transaction_status === 'SUCCESS') {
        totalPayment = 0;
        payments = [];
      }

      this.setState({ paymentData: payments });
      this.setState({ totalMoney: totalPayment });
    });

  }

  render() {
    let width = window.innerWidth;
    let mdWidth = width === 768 ? 12 : 9;
    return (
      <React.Fragment>
        {this.state.redirect && <Redirect to="/signin" />}
        {this.state.loading ? (
          <Spinner color="primary" />
        ) : (
          <Col
            className="justify-content-center align-items-center"
            md={mdWidth}
            xs={12}
            style={{ marginBottom: '7%', width: '100%' }}
          >
            <PayBar
              amount={this.state.totalMoney}
              data={this.state.data}
              paymentData={this.state.paymentData}
              notification={this.props.notification}
            />

            {Object.keys(this.state.data).length !== 0 && (
              <HTable data={this.state.data} />
            )}
          </Col>
        )}
      </React.Fragment>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(UserDashboard);
