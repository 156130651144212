import React from 'react';
import axios from 'axios';

import { Grid, Typography, Button, TextField } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import PasswordField from 'material-ui-password-field';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import useStyles from '../styles/signin';
import { useState, useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

function SignIn() {
  var ls = require('local-storage');

  var classes = useStyles();

  var notificationSystem = useRef();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [redirectDash, doRedirectDash] = useState(false);
  const [redirectHome, doRedirectHome] = useState(false);
  const [redirectForgotPass, doRedirectForgotPass] = useState(false);

  const handleChange = name => event => {
    if (name === 'username') setUsername(event.target.value);
    else if (name === 'password') setPassword(event.target.value);
  };
  const gotoForgortPassword = e => {
    e.preventDefault();
    doRedirectForgotPass(true);
  };
  const authenticate = e => {
    e.preventDefault();

    // Adding this notification temporary to stop logins
    // notificationSystem.current.addNotification({
    //   title: 'Login',
    //   message:
    //     "Admin have stopped login for time being, you'll be notified by SMS/Email soon once we are available!",
    //   level: 'error',
    //   position: 'tr',
    // });
    if(parseInt(username) <= 1256 ){
      doRedirectHome(true);
    }
    axios
      .post('/api/users/auth', {
        body: {
          username,
          pass: "password",
        },
      })
      .then(response => {
        ls('ima-auth-token', response.data.token);
        doRedirectDash(true);
      })
      .catch(err => {
        notificationSystem.current.addNotification({
          title: 'Login',
          message: 'Incorrect Credentials!',
          level: 'error',
          position: 'tc',
        });
      });
  };

  return (
    <Grid
      container
      className={classes.container}
      style={{ width: 'auto', margin: '3%', backgroundColor: 'transparent' }}
    >
      {redirectHome === true && <Redirect to="/" />}
      {redirectDash === true && <Redirect to="/user/dashboard" />}
      {redirectForgotPass === true && <Redirect to="/forgotPassword" />}

      <div className={classes.formContainer}>
        <NotificationSystem
          dismissible={false}
          ref={notificationSystem}
          style={NOTIFICATION_SYSTEM_STYLE}
        />
        <div className={classes.form}>
          <React.Fragment>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Pay FFC
              </Typography>
              <form className={classes.form2} noValidate>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={8}>
                      <h6>SSS No</h6>
                      <TextField
                        autoComplete="userid"
                        name="userid"
                        variant="outlined"
                        required
                        onChange={handleChange('username')}
                        fullWidth
                        id="uid"
                        autoFocus
                        // variant="outlined"
                      />
                      {/* <FormHelperText>User ID</FormHelperText> */}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justify="center"
                    spacing={2}
                  >
                   {/*
                    <Grid item xs={12} sm={8}>
                      <h6>Password</h6>
                      <PasswordField
                        autoComplete="password"
                        name="password"
                        required
                        type="Submit"
                        fullWidth
                        onChange={handleChange('password')}
                        id="password"
                      />
                    </Grid>
                    */}
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="center"
                    sm={4}
                    xs={6}
                  >
                    <Button
                      onClick={authenticate}
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.submit}
                    >
                     Enter
                    </Button>
                  </Grid>

                  {/*
                  <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                    sm={12}
                    xs={12}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={gotoForgortPassword}
                    >
                      Forgot Password?
                    </Button>
                  </Grid>
                  */}
                </Grid>
              </form>
            </div>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(SignIn);
