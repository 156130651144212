import React from 'react';
import LazyLoad from 'react-lazyload';
function Committee() {
  return (
    <div>
      <section className="wpb_row row-fluid section-padd no-top">
        <div className="container">
          <div className="row">
            <div className="wpb_column column_container col-sm-12">
              <div className="column-inner mt-3">
                <div className="wpb_wrapper">
                  <div className="section-head ">
                    <center>
                      {/* <h3>
                        <span>
                          INDIAN MEDICAL ASSOCIATION MAHARASHTRA STATE
                        </span>
                      </h3>
                      <h3>
                        <span>SOCIAL SECURITY SCHEME</span>
                      </h3> */}

                      <h3
                        className="section-title"
                        style={{ color: 'darkblue' }}
                      >
                        MANAGING COMMITTEE 2024 – 25
                      </h3>
                    </center>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/IMA_pres.jpg')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Santosh Kadam       
                        <span className="font1 normal">IMA MS President</span>
                        <span className="font1 normal">(Thane)</span>
                        <span className="font1 normal">M. 09820245526</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/HSS.jpg')}
                          alt=""
                          height="300px"
                        />
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Anil Avhad 
                        <span className="font1 normal">
                          IMA Hon. State Secretary
                        </span>
                        <span className="font1 normal">(Mumbai)</span>
                        <span className="font1 normal">M. 09867038430</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_19.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Saurabh Sanjanwala        
                        <span className="font1 normal">IMA Hon. Treasurer</span>
                        <span className="font1 normal">(MWS)</span>
                        <span className="font1 normal">M. 09930087140</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>

            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_1.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Rajgopal Kalani
                        <span className="font1 normal">Chairman</span>
                        <span className="font1 normal">(Parbhani)</span>
                        <span className="font1 normal">M. 09422176227</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>

            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_7.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Jayant Navarange
                        <span className="font1 normal">Imm. Past Chairman</span>
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">M. 09890206303</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>

            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_8.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Anil Panse 
                        <span className="font1 normal">Hon. Secretary </span>
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">M. 09823044757</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>

            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_20.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Suhas Nene
                        <span className="font1 normal">Hon. Treasurer</span>
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">M. 09822115335             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_10.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Ambrish Shahade

                        <span className="font1 normal">Hon. Jt. Secretary</span>
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">M. 09422616464             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>

            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_12.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Abbas Jable
                        <span className="font1 normal">Member                       </span>
                        <span className="font1 normal">(Chiplun)</span>
                        <span className="font1 normal">M. 09881061646            </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_27.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Neeraj Murkey 
                        <span className="font1 normal">Member                       </span>
                        <span className="font1 normal">(Yavatmal)</span>
                        <span className="font1 normal">M. 09422918854            </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_5.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Prakash Marathe
                        <span className="font1 normal">Member                       </span>
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">M. 09823071292	             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_15.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Raju Varyani
                        <span className="font1 normal">Member                       </span>
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">M. 09822646025             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>

            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_3.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Sanjeev Chitnavis
                        <span className="font1 normal">Member                       </span>
                        <span className="font1 normal">(Gondia)</span>
                        <span className="font1 normal">M. 09823111292             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_32.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Satyen Mantri
                        <span className="font1 normal">Member                       </span>
                        <span className="font1 normal">(Akola)</span>
                        <span className="font1 normal">M. 09822062958             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_11.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Shivaji Kakade
                        <span className="font1 normal">Member                       </span>
                        <span className="font1 normal">(Majalgaon)</span>
                        <span className="font1 normal">M. 09422242982             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_16.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Snehal Fegade
                        <span className="font1 normal">Member                       </span>
                        <span className="font1 normal">(Jalgaon)</span>
                        <span className="font1 normal">M. 09226893906             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_6.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Umesh Rewanwar
                        <span className="font1 normal">Member                       </span>
                        <span className="font1 normal">(Pusad)</span>
                        <span className="font1 normal">M. 09422165480             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_37.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Ashish Disawal
                        <span className="font1 normal">           Co-opted Members</span>
                        <span className="font1 normal">(Nagpur)</span>
                        <span className="font1 normal">M. 09822307500             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/DrGomare.jpg')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Mangala Gomare
                        <span className="font1 normal">           Co-opted Members</span>
                        <span className="font1 normal">(Mumbai)</span>
                        <span className="font1 normal">M. 09833898688              </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/Chandra.jpg')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Chandrashekhar Purandare      
                        <span className="font1 normal">           Co-opted Members</span>
                        <span className="font1 normal">(Sangli)</span>
                        <span className="font1 normal">M. 09822338523             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_28.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Pradipkumar Sangale
                        <span className="font1 normal">           Co-opted Members</span>
                        <span className="font1 normal">(Kalyan)</span>
                        <span className="font1 normal">M. 09930842690             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_21.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Sunil Sanghai
                        <span className="font1 normal">           Co-opted Members</span>
                        <span className="font1 normal">(Dr. Pradipkumar Sangale)</span>
                        <span className="font1 normal">M. 08788292218             </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wpb_row row-fluid section-padd no-top">
        <div className="container">
          <div className="row">
            <div className="wpb_column column_container col-sm-12">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="section-head ">
                    <center>
                      <h3
                        className="section-title"
                        style={{ color: 'darkblue' }}
                      >
                        THE HALL OF FAME
                      </h3>

                      <h4 style={{ color: 'red' }}>CHAIRMAN</h4>
                    </center>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/18.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Late Dr. Y. Kelkar
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">1990-1997</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/19.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. R. G. Jimulia
                        <span className="font1 normal">(Mumbai West)</span>
                        <span className="font1 normal">1997-2002</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/20.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Ram Arankar
                        <span className="font1 normal">(Indapur)</span>
                        <span className="font1 normal">2002-2005</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>

            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/21.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. A. D. Brahmankar
                        <span className="font1 normal">(Bhandara)</span>
                        <span className="font1 normal">2005-2008</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>

            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/22.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        {' '}
                        Dr. Anil Talati
                        <span className="font1 normal">(Pen)</span>
                        <span className="font1 normal">2008-2011</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/23.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Late. Dr. Shrikant Kothari
                        <span className="font1 normal">(NEBS)</span>
                        <span className="font1 normal">2011-2014</span>
                        <span className="font1 normal"><b>Hon. Secretary</b>:2005-2011</span>
                        <span className="font1 normal"><b>Hon. Secretary</b>:2018-2019</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/24.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Prashant Nikhade
                        <span className="font1 normal">(Nagpur)</span>
                        <span className="font1 normal">2014-2017</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/25.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        (Late) Dr. Shailendra Mehtalia
                        <span className="font1 normal">(Mumbai West)</span>
                        <span className="font1 normal">2017-2018</span>
                        <span className="font1 normal"><b>Hon. Secretary</b>:2012-2017</span>
                        <span className="font1 normal"><b>Hon Treasurer/Jt Secretary</b>:2005-2012</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/26.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Jayant Navarange
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">2018 - 2023</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wpb_row row-fluid section-padd no-top">
        <div className="container">
          <div className="row">
            <div className="wpb_column column_container col-sm-12">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="section-head ">
                    <center>
                      <h4 style={{ color: 'red' }}>HON. SECRETARY</h4>
                    </center>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/27.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Late Dr. Anand Gokhale
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">1990 – 1998</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_23.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Sham Damle
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">1998 – 2005</span>
                        <span className="font1 normal">2021 – 2022</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>


            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/30.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Parthiv Sanghvi
                        <span className="font1 normal">(Mumbai West)</span>
                        <span className="font1 normal">2011 – 2012</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>


            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/32.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. S. K. Joshi
                        <span className="font1 normal">(Mumbai West)</span>
                        <span className="font1 normal">2017-2018</span>
                        <span className="font1 normal"><b>Hon Jt. Sec</b>:April 13 – Nov 13          </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>



            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/5.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Prakash Marathe
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">2019 - 2021</span>
                        <span className="font1 normal">2022 - 2024 </span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpb_row row-fluid section-padd no-top">
        <div className="container">
          <div className="row">
            <div className="wpb_column column_container col-sm-12">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="section-head ">
                    <center>
                      <h4 style={{ color: 'red' }}>
                        HON. TREASURER / JT. SECRETARY{' '}
                      </h4>
                    </center>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/34.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Subhash Behere
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">1990 – 2001</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_8.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Anil Panse
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">2001 – 2005</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>


            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/37.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Kaizer Barot
                        <span className="font1 normal">(Mumbai)</span>
                        <span className="font1 normal">2012 - 2013</span>
                        <span className="font1 normal"><b>Hon. Treasurer </b>2013-2016</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpb_row row-fluid section-padd no-top">
        <div className="container">
          <div className="row">
            <div className="wpb_column column_container col-sm-12">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="section-head ">
                    <center>
                      <h4 style={{ color: 'red' }}>HON. TREASURER </h4>
                    </center>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>

            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/39.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>

                    <div className="mem-info">
                      <h5>
                        Dr. Pragji Vaja
                        <span className="font1 normal">(Mumbai)</span>
                        <span className="font1 normal">2016 - 2019</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/6.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>

                    <div className="mem-info">
                      <h5>
                        Dr. Meenakshi Deshpande
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">2019 - 2021</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpb_row row-fluid section-padd no-top">
        <div className="container">
          <div className="row">
            <div className="wpb_column column_container col-sm-12">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="section-head ">
                    <center>
                      <h4 style={{ color: 'red' }}>JT. SECRETARY</h4>
                    </center>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./output_images/image_20.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>
                    <div className="mem-info">
                      <h5>
                        Dr. Suhas Nene
                        <span className="font1 normal">Hon. Treasurer</span>
                        <span className="font1 normal">(Pune)</span>
                        <span className="font1 normal">2021 - Contd...</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/41.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>

                    <div className="mem-info">
                      <h5>
                        Dr. Urmila Garg
                        <span className="font1 normal">(Mumbai West)</span>
                        <span className="font1 normal">2013-2019</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-3">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="member-item radius">
                    <div className="avatar">
                      <LazyLoad
                        height="100%"
                        placeholder={
                          <img
                            src="https://via.placeholder.com/750x695"
                            alt=""
                            height="300px"
                          />
                        }
                        once={true}
                      >
                        <img
                          src={require('./img/8.png')}
                          alt=""
                          height="300px"
                        />{' '}
                      </LazyLoad>
                    </div>

                    <div className="mem-info">
                      <h5>
                        Dr. Ambrish Shade
                        <span className="font1 normal">(Mumbai West)</span>
                        <span className="font1 normal">2019 - contd</span>
                      </h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Committee;
