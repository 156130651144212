import React from 'react';
import NewUpdates from './newUpdate';
import About from './about';
import ByLaws from './by_laws';
import AboutUs from './aboutUs';
import FAQ from './faqs/faq';
import BannerSlider from './bannerSlider';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <NewUpdates />
        <BannerSlider />
        <About />
        <ByLaws />
        <AboutUs />
{/*         <FAQ /> */}
      </React.Fragment>
    );
  }
}
export default Home;
