import React from 'react';
import componentQueries from 'react-component-queries';
import 'styles/reduction.scss';
import { Row } from 'reactstrap';
import PaybarModalAnnual from './PaybarModalAnnual';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
// const TopBarPage = React.lazy(() => import('./pages/TopBarPage'));
// react-icons/md
class PayBar extends React.Component {
  render() {
    return (
      <Row
        className="d-flex flex-row-reverse"
        xs={12}
        style={{ width: '100%' }}
      >
        <span className="p-2">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <h5 className="pr-2" style={{ margin: '0' }}>
              {' '}
              Advanced Voluntary Deposit With Us: {'\u20B9'}
              {parseInt(this.props.data.voluntary_advanced_deposit)}
            </h5>
            {this.props.data.total_unit ? (
              <h5 className="pr-2" style={{ margin: '0' }}>
                {' '}
                Total Units: {Math.max(this.props.data.total_unit, 1)}
              </h5>
            ) : null}
            <h5 className="pr-2" style={{ margin: '0' }}>
              {' '}
              Net Payable: {'\u20B9'}
              {Math.max(this.props.amount, 0)}
            </h5>
            <div className="pr-2">
              <PaybarModalAnnual
                data={this.props.data}
                notification={this.props.notification}
                paymentData={this.props.paymentData}
                amount={this.props.amount}
              />
            </div>
            {window.innerWidth === 768 && (
              <div className="pr-2">
                <Button
                  component={Link}
                  to="/user/dashboard"
                  variant="contained"
                  color="secondary"
                  style={{ color: 'white' }}
                >
                  Home
                </Button>
              </div>
            )}
          </div>
        </span>
      </Row>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(PayBar);
